.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

iframe {
  z-index: -5 !important;
}

.rs-picker-daterange-menu {
  z-index: 1300 !important;
}

.rs-icon {
  height: 100%;
}

.rs-picker-daterange-menu {
  z-index: 1300 !important;
}

.rs-icon {
  height: 100%;
}

.ReactTags__tagInputField {
  background-color: white;
}

span.rs-picker-toggle-value {
  color: black !important;
}

.rs-picker-toggle:hover {
  border-color: #364656 !important;
}

.rs-picker-toggle-active {
  box-shadow: none !important;
  border-color: #364656 !important;
}

.rs-calendar-table-cell-selected .rs-calendar-table-cell-content {
  background-color: #364656 !important;
}

.rs-calendar-table-cell-selected .rs-calendar-table-cell-content {
  color: white;
}
.rs-calendar-table-cell-is-today .rs-calendar-table-cell-content {
  box-shadow: inset 0 0 0 1px #364656 !important;
}

.rs-picker-menu .rs-calendar .rs-calendar-table-cell:hover .rs-calendar-table-cell-content {
  color: white !important;
}

.rs-calendar-table-cell-in-range:before {
  background-color: #f5f5f5 !important;
}

.rs-btn-link {
  color: #364656 !important;
}

.rs-btn-primary {
  background-color: #364656 !important;
}

.rs-btn-lg {
  height: 56px !important;
  padding-top: 15px !important;
  border-radius: 5px !important;
  border-color: rgb(196, 196, 196) !important;
}

.rs-icon {
  padding-top: 8px !important;
}

.swiper-button-next {
  color: #ffff !important;
}

.swiper-button-prev {
  color: #ffff !important;
}

.rs-btn-md {
  height: 43px !important;
  padding-top: 12px !important;
  border-radius: 5px !important;
  border-color: rgb(54, 70, 86, 0.5) !important;
  font-size: 16px !important;
}

/*textinput type number's arrows stay visible*/
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  opacity: 1;
}

.custom-recharts-tooltip {
  margin: 0px;
  padding: 10px;
  background-color: rgb(255, 255, 255);
  border: 1px solid rgb(204, 204, 204);
  white-space: nowrap;
}

.custom-recharts-metric {
  color: rgba(86, 111, 136, 0.7);
}

.share-page-react-player {
  height: auto !important;
  aspect-ratio: 16/9;
}
